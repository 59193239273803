import React from "react";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 776px) {
    overflow: visible;
    height: auto;
  }
`;

const Background = styled.div`
  &:after {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("/images/pattern-bodyBackground.png");
  }

  &:before {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(342deg, #ff0000 0%, #ff9900 100%);
  }
`;

const Logo = styled.div``;

const Header = styled.header``;

const Content = styled.main`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.7;
  position: relative;
  height: 100vh;
  padding: 5rem;
  min-height: 60rem;
  max-width: 80rem;
  top: 0;
  width: 100%;

  a {
    color: #fff;
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.5) 50%
    );

    background-repeat: repeat-x;
    background-size: 2px 2px;
    background-position: 0 1.175em;
    display: inline;
    text-decoration: none;

    &:hover {
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0.75) 50%
      );
      text-decoration: none;
    }

    &:focus {
      outline: 1px auto rgba(255, 255, 255, 0.5);
      outline-offset: -2px;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 776px) {
    font-size: 2rem;
    padding: 1.5rem;
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /* .site--content {
    opacity: 0;
    animation: fade 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  } */
`;

const IndexPage = () => {
  return (
    <>
      <GlobalStyle />

      <Helmet>
        <meta charset="utf-8" />
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:url" content="https://atomidesign.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Atomi" />
        <meta
          property="og:description"
          content="Atomi is a design &amp; development studio based in San Francisco working with great companies like you."
        />
        <meta
          property="og:image"
          content="https://atomidesign.com/images/atomi-facebook.png"
        />

        <title>Atomi Design – Full Service Studio</title>
        <link rel="stylesheet" href="styles/main.css" />

        <link
          href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>

      <Container>
        <Background />

        <Content>
          <div>
            <Header>
              <Logo>
                <StaticImage
                  style={{ width: "120px" }}
                  alt="Logo"
                  placeholder="tracedSVG"
                  src="../images/atomi-logo.svg"
                />
              </Logo>
            </Header>

            <main>
              <p>
                Atomi is a full service design &amp; development studio based in
                Lake Tahoe working with great companies like{" "}
                <a
                  href="https://fellowproducts.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fellow
                </a>
                ,{" "}
                <a href="https://taika.co/" target="_blank" rel="noreferrer">
                  Taika
                </a>
                ,{" "}
                <a
                  href="https://www.protonradio.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Proton Radio
                </a>
                ,{" "}
                <a href="https://peruse.io/" target="_blank" rel="noreferrer">
                  Peruse
                </a>
                , and more. Past clients include{" "}
                <a
                  href="https://apptimize.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apptimize
                </a>
                ,{" "}
                <a href="https://razer.com" target="_blank" rel="noreferrer">
                  Razer
                </a>
                ,{" "}
                <a href="https://flywheel.com" target="_blank" rel="noreferrer">
                  Flywheel
                </a>
                , and{" "}
                <a
                  href="https://www.glassfrog.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  many
                </a>
                ,{" "}
                <a
                  href="https://hackbrightacademy.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  more.
                </a>
              </p>
              <p>
                <a
                  href="&#109;&#097;&#105;&#108;&#116;&#111;:&#114;&#111;&#098;&#064;&#097;&#116;&#111;&#109;&#105;&#100;&#101;&#115;&#105;&#103;&#110;&#046;&#099;&#111;&#109;?&#115;&#117;&#098;&#106;&#101;&#099;&#116;=&#072;&#097;&#112;&#112;&#121;&#032;"
                  id="atomi-email"
                  target="_blank"
                  rel="noreferrer"
                >
                  Have a project you'd like to discuss? Let's talk.
                </a>
              </p>
            </main>
          </div>
        </Content>
      </Container>
    </>
  );
};

export default IndexPage;
